import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpotify, faApple } from "@fortawesome/free-brands-svg-icons";

import mountains from '../images/mountains.png';

function Hero() {
  return (
    <div className="container" class="text-center lg:pt-40">
        <div className="title">
          Missionary Dating
        </div>
        <div className="mountains">
          <img src={mountains} alt='Two mountains a man proposing to a woman at the bottom of them' loading='eager' sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, 95vw" />
        </div>
        <div className="subtitle" class="font-montserrat font-semibold lg:p-4 lg:text-xl">
          POSITIONS ON FINDING LOVE IN & OUT OF THE CHURCH
        </div>
        <div className="icon-container">
          <a href='https://podcasters.spotify.com/pod/show/andywalt' target='_blank' rel="noopener noreferrer" >
            <FontAwesomeIcon icon={faSpotify} size='5x' className='icon' beat />
          </a>
          <a href='https://podcasts.apple.com/us/podcast/missionary-dating/id1709735048' target='_blank' rel="noopener noreferrer" >
            <FontAwesomeIcon icon={faApple} size='5x' className='icon' beat />
          </a>
        </div>
        <div className="podcast-description">
          A cheeky podcast that's here to help you laugh instead of cry.
        </div>
      </div>
  )
}

export default Hero;
