import React from 'react'

import miniLogo from "../images/MissionaryDating-Logo.png";

function Header() {
  return (
    <div>
      <div className="logo" class="flex justify-center items-center lg:fixed lg:top-0 lg:left-0">
          <img src={miniLogo} alt='Missionary Dating Logo - heart shaped with MD in the middle' class="max-h-60" />
        </div>
      
    </div>
  )
}

export default Header
